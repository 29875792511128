<template>
  <div class="book-detail">
    <p class="title">设计和开发的理解与应用</p>
    <div>
      <p class="ptittle">书本介绍</p>
      <span
        >本书从企业设计和开发管理的实际出发，去剖析和理解ISO9001中设计和开发的要求，帮助企业将国际标准要求融入到企业设计开发管理中，而非生搬硬套。</span
      >
    </div>
    <br />
    <div>
      <p class="ptittle">内容介绍</p>
      <span
        >从1987年第一版标准发布至今，设计和开发一直是ISO9001实施和认证的难点，这除了它涉及的条款所占的比例最大外，还因为相对于其他条款而言涉及的专业程度较深。组织的设计受到行业特征的制约而遵循着自身的习惯模式，具有产品范围的设计专业和系统特征，这种系统特征无可避免地带有管理的性质。但设计人员往往不理解9001所要求的设计系统，当面对标准条款的要求时就似乎觉得自己不懂得设计和管理，本书从企业的实际出发，去剖析和理解ISO9001中涉及设计和开发的要求。
        只要企业实施ISO9001，要么将标准要求融入他们的设计系统中，要么将他们的设计系统融入标准要求中，这两种出发点所产生的系统效果完全不一样。但由于普遍存在对设计和开发理解的困难，结果往往是后者居多，照搬条款，教条和形式化。其实，企业是因为管理的需要而采纳标准，而不是因为标准才需要管理。企业希望的是将ISO9001要求纳入他们的设计系统后仍然保持着一种自然的而不是扭曲的状态，是提高设计的效率而不是增加负担。ISO9001所强调的过程方法正是以过程反映条款，而不是以条款反映过程。
      </span>
      <br />
      <span>
        很多企业面临困境的时候很容易就会想到流程优化。不过，对流程优化跃跃欲试的企业只钟情于成功的一面，殊不知不成功的例子比成功的多得多。不是没有决心，不是没有专家，而是每次的实践都不一样，每次都摸着石头过河，而真正的主角不是专家而是企业自己，专家能有三成的帮助已经了不起了。那么，能否有一种方法完全由企业自己去主导过程优化呢？笔者一直在探索这种方法，本书向大家呈现的就是它的研究成果。
      </span>
      <br />
      <br />
      <div class="divp">
        <span>本书分为5个章节：</span>
        <p><span>第一章</span><span>设计和开发概论</span></p>
        <p><span>第二章</span><span>设计和开发的要求</span></p>
        <p><span>第三章</span><span>设计和开发的类型</span></p>
        <p><span>第四章</span><span>设计和开发文件及控制</span></p>
        <p><span>第五章</span><span>概念区别及其术语解释</span></p>
      </div>
      <br />
      <br />
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style lang="less" scoped>
@import "../../../assets/style/BookStyle.css";
</style>